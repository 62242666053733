import React, { useState, useContext } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';

import { Edit } from '@mui/icons-material';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import { chooseGender, chooseContactPersonPosition, chooseMemberType, getBoolean, getDate, getPerson } from '../../../../../helpers/database';

import { CompanyContext } from '../../CompanyContext';
import CompanyMasterPopUp from '../../../../popups/CompanyMaster';
import CustomButton from '../../../../common-components/CustomButton';
import { swvTheme } from '../../../../../swvTheme';
import PersonPopUp from '../../../../popups/Person/Person';

export default function ReadOnlyTables({ onPersonModalOpen, contactPersonList, updateCompany, getCompany, setCompanyData }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { companyData } = useContext(CompanyContext);

  const createData = (name, value) => {
    return { name, value };
  };

  // CompanyMasterPopUp

  const [isCompanyMasterPopUpOpen, setIsCompanyMasterPopUpOpen] = useState(false);

  const onCompanyMasterPopupOpen = () => {
    setIsCompanyMasterPopUpOpen(true);
  };

  const onCompanyMasterPopupClose = async () => {
    setIsCompanyMasterPopUpOpen(false);
  };

  const tableRightRows = [
    createData('Name:', getPerson(companyData.Master?.Person)),
    createData('Geburtsdatum:', getDate(companyData.Master?.Person?.Birthdate)),
    createData('Geschlecht:', chooseGender(companyData.Master?.Person?.Gender)),
    createData('Position:', chooseContactPersonPosition(companyData.Master?.Position)),
    createData('SWV Mitglied:', chooseMemberType(companyData.Master?.Person?.Member?.Type)),
    createData('SWV Familie:', getBoolean(companyData.Master?.Person?.Family)),
  ];

  return (
    <>
      <Grid container mt={3} columnSpacing={6}>
        <Grid xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TableContainer sx={{ borderRadius: '8px' }}>
              <Box sx={{ p: 2 }}>
                <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500}>
                  Kontaktperson
                </Typography>
                {/* <IconButton aria-label="edit" color="primary" onClick={() => onPersonModalOpen(companyData.Master)}>
                  <Edit />
                </IconButton> */}
              </Box>
              <Table>
                <TableBody className={cssClass.customTableBody}>
                  {tableRightRows?.map((row) => (
                    <TableRow key={row.ID}>
                      <TableCell className={cssClass.shrinkCell}>{row.name}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 2,
              mt: 3,
            }}
          >
            <CustomButton value="Person" onClick={() => onCompanyMasterPopupOpen()} sx={{ width: '100% !important' }} />
          </Box>
        </Grid>
      </Grid>

      <CompanyMasterPopUp
        isOpen={isCompanyMasterPopUpOpen}
        onClose={onCompanyMasterPopupClose}
        contactPersonList={contactPersonList}
        companyData={companyData}
        updateCompany={updateCompany}
        getCompany={getCompany}
        setCompanyData={setCompanyData}
        onPersonModalOpen={onPersonModalOpen}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CompanyContext } from '../../CompanyContext';
import DownloadCell from '../../../../common-components/DownloadCell';
import DeleteCell from '../../../../common-components/DeleteCell';
import BoxLeft from '../../../../common-components/BoxLeft';
import AddIcon from '@mui/icons-material/Add';

import { getDate, getPerson } from '../../../../../helpers/database';
import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

import * as endpoints from '../../../../../constants/endpoints';
import { swvTheme } from '../../../../../swvTheme';

export default function PhotoTable({
  onPhotoModalOpen,
  downloadPhoto,
  deletePhoto,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const { photoList, chosenPhoto } = useContext(CompanyContext);


  const [photoList64, setPhotoList64] = useState < Array < string >> ([]);
  useEffect(() => {
    photoList.forEach(photo => {
      
    });
  }, [photoList])

  return (
    <Grid xs={12}>
      <Box className={cssClass.flexColumnCenter}>

        <TableContainer sx={{ borderRadius: '8px' }}>
          <Typography color={swvTheme.typography.lilac} fontSize={18} fontWeight={500} p={2}>
            Photos
          </Typography>

          {photoList?.map((photo) => (
            <Grid item xs={12} key={photo.ID} p={2}>
              <Box>
                <img
                  src={endpoints.apiUrlEx + endpoints.getPhotoSrc + "?id=" + photo.ID + "&date=" + Date.now()}
                  // src="/logo512.png" // Replace with real images
                  alt={photo.Originalname}
                  style={{ width: '100%' }}
                />
              </Box>
            </Grid>
          ))}

          <Box ml={2} mr={2} sx={{ borderBottom: '1px dotted #ccc' }} >
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'flex-end',
              padding: 2,
              cursor: 'pointer',
            }}
          >
            <AddIcon sx={{ fill: '#F50000' }} onClick={() => onPhotoModalOpen()} />
          </Box>
        </TableContainer>

      </Box>
    </Grid>
  );
}

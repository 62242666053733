import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

export default function Spinner({ text }) {
  const classes = useStyles();

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
      }}
    >
      <Typography variant="h5">{text}</Typography>
      <CircularProgress />
    </Box>
  );
}

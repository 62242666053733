import React, { useEffect, useState, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useSnackbarHelpers } from '../../../../../helpers/utils';
import { getPerson as getPersonEx } from '../../../../../helpers/database';

import * as PersonService from '../../../../../services/requests/person';
import * as FunctionService from '../../../../../services/requests/function';
import { FunctionDetailsContext } from './FunctionDetailsContext';
import FormSelect from '../../../../common-components/FormSelect';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';
import PersonPopUp from '../../../../popups/Person';
import BusinessGroupPopUp from '../../../../popups/BusinessGroup';

import { AdminOrganisationContext } from '../../AdminOrganisationContext';

import CustomSelect from '../../../../common-components/CustomSelect';
import BoxCenter from '../../../../common-components/BoxCenter';
import FormTextField from '../../../../common-components/FormTextField';
import FormDatePicker from '../../../../common-components/FormDatePicker';

import { useStyles } from './styles';
import { BusinessCategoryAPI } from "../../../../../redux/services/BusinessCategoryService";
import { PanelAPI } from "../../../../../redux/services/PanelService";

export default function FunctionDetails() {
  const classes = useStyles();

  const {
    chosenFunction,
    getFunctionList,
    chosenOrganisation,
    setChosenFunction,
  } = useContext(AdminOrganisationContext);

  const { data: businessCategoryListResponse } = BusinessCategoryAPI.useGetListQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data: panelListResponse } = PanelAPI.useGetListQuery(chosenOrganisation?.ID, { refetchOnMountOrArgChange: true });

  const [isPersonPopUpOpen, setIsPersonPopUpOpen] = useState(false);
  const [isBusinessGroupPopUpOpen, setIsBusinessGroupPopUpOpen] = useState(false);
  const [chosenPerson, setChosenPerson] = useState(null);

  const { } = useSnackbarHelpers();

  const { control, reset, setValue } = useForm({
    defaultValues: {
      businessCategory: '',
      panel: '',
      panelName: '',
      businessGroup: '',
      businessGroupNumber: '',
      businessGroupName: '',
      name: '',
      member: '',
      start: '',
      end: '',
      isActive: '',
      isVolunteer: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (chosenFunction) {
      reset({
        businessCategory: chosenFunction?.BusinessCategory?.ID || '',
        panel: chosenFunction?.Panel?.ID || '',
        businessGroup: chosenFunction?.BusinessGroup?.ID,
        businessGroupNumber: chosenFunction?.BusinessGroup?.Number,
        businessGroupName: chosenFunction?.BusinessGroup?.Name,
        name: chosenFunction?.Name,
        member: chosenFunction?.Member?.ID,
        memberName: getPersonEx(chosenFunction?.Member?.Person),
        start: chosenFunction?.Start,
        end: chosenFunction?.End,
        isActive: chosenFunction?.IsActive,
        isVolunteer: chosenFunction?.IsVolunteering,
      });
    }
  }, [chosenFunction]);

  const onMemberApply = () => {
    const defaultValues = {
      ...values,
      member: chosenPerson?.Member?.ID,
      memberName: getPersonEx(chosenPerson),
    };
    reset(defaultValues);

    onPersonModalClose();
  };

  const onSaveClicked = async () => {
    const preload = {
      ID: chosenFunction?.ID,
      Name: values.name,
      FK_BusinessCategory: values.businessCategory || '',
      FK_Panel: values.panel || '',
      FK_BusinessGroup: values.businessGroup || '',
      FK_Member: values.member,
      Start: values.start || null,
      End: values.end || null,
      IsActive: values.isActive,
      IsVolunteering: values.isVolunteer,
    };

    const success = async (data) => {
      setChosenFunction(data);
      await getFunctionList();
    };

    if (chosenFunction?.ID) {
      await FunctionService.updateOne(preload, success);
    } else {
      await FunctionService.insertOne(preload, success);
    }
  };

  // BusinessGroupPopUp

  const onBusinessGroupModalOpen = async () => {
    setIsBusinessGroupPopUpOpen(true);
  };

  const onBusinessGroupModalClose = async () => {
    setIsBusinessGroupPopUpOpen(false);
  };

  const onBusinessGroupApply = (businessGroup) => {
    setValue('businessGroup', businessGroup.ID);
    setValue('businessGroupNumber', businessGroup.Number);
    setValue('businessGroupName', businessGroup.Name);
    setIsBusinessGroupPopUpOpen(false);
  };

  // PersonPopUp

  const onPersonModalOpen = async () => {
    if (chosenFunction?.Member?.Person) {
      await PersonService.getOne(chosenFunction?.Member?.Person.ID, setChosenPerson);
    } else {
      setChosenPerson(null);
    }

    setIsPersonPopUpOpen(true);
  };

  const onPersonModalClose = async () => {
    setChosenPerson(null);
    setIsPersonPopUpOpen(false);
  };

  const disabled =
    !values.name ||
    values.isVolunteer === '' ||
    !values.member ||
    values.isActive === '' ||
    (!values.businessCategory && !values.panel && !values?.businessGroup);

  return (
    <Container maxWidth="sm">
      <FunctionDetailsContext.Provider
        value={{
          chosenPerson,
          setChosenPerson,
        }}
      >
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormSelect
              label="Sparte"
              name="businessCategory"
              control={control}
              value={values?.businessCategory}
            >
              {businessCategoryListResponse?.data?.map((row) => {
                return (
                  <MenuItem
                    key={row.ID}
                    value={row.ID}
                  >
                    {row.Name}
                  </MenuItem>
                );
              })}
            </FormSelect>
            <FormSelect
              label="Gremium"
              name="panel"
              control={control}
              valueTo
              value={values?.panel}
            >
              {panelListResponse?.data?.map((row) => {
                return (
                  <MenuItem
                    key={row.ID}
                    value={row.ID}
                  >
                    {row.Name}
                  </MenuItem>
                );
              })}
            </FormSelect>
            <CustomSelect
              placeholder='Berufsgruppe'
              name="businessGroup"
              value={values?.businessGroup}
              valueToDisplay={values?.businessGroup ? values?.businessGroupNumber + ', ' + values?.businessGroupName : ''}
              onClick={onBusinessGroupModalOpen}
            />
            <FormTextField
              name="name"
              label="Name"
              control={control}
              required
            />
            <CustomSelect
              placeholder="Mitglied"
              value={values?.member}
              valueToDisplay={values?.member ? values.memberName : ''}
              required
              onClick={onPersonModalOpen}
            />
            <FormDatePicker
              name="start"
              label="Anfang"
              control={control}
              required
            />
            <FormDatePicker
              name="end"
              label="Ende"
              control={control}
            />
            <FormBooleanSelect
              label="Status"
              name="isActive"
              control={control}
              required
              value={values?.isActive}
              trueText="Aktiv"
              falseText="inaktiv"
            />
            <FormBooleanSelect
              label="Ehrenamt"
              name="isVolunteer"
              control={control}
              required
              value={values?.isVolunteer}
            >
            </FormBooleanSelect>
            <BoxCenter>
              <Button
                disabled={disabled}
                onClick={() => onSaveClicked()}
              >
                Speichern
              </Button>
            </BoxCenter>
          </LocalizationProvider>
        </FormControl>

        <BusinessGroupPopUp
          isOpen={isBusinessGroupPopUpOpen}
          onClose={onBusinessGroupModalClose}
          businessCategoryList={businessCategoryListResponse?.data}
          masterBusinessGroupId={values?.businessGroup}
          handleApply={onBusinessGroupApply}
        />

        <PersonPopUp
          isOpen={isPersonPopUpOpen}
          onClose={onPersonModalClose}
          chosenPerson={chosenPerson}
          setChosenPerson={setChosenPerson}
          onMemberApply={onMemberApply}
        />
      </FunctionDetailsContext.Provider>
    </Container>
  );
}

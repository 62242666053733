import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

/*export const getList = async (companyId, success, error) => {
  console.log("PhotoService getList", companyId)
  const response = await apiClient.getListNew(endpoints.getPhotoList, {}, { companyId }, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.postForm(endpoints.insertPhoto, data, success, error);
  return response.data;
};

export const deleteOne = async (id, success, error) => {
  const response = await apiClient.deleteOne(endpoints.deletePhoto, id, success, error);
  return response.data;
};

export const downloadOne = async (id, filename, success, error) => {
  return await apiClient.downloadOne(endpoints.downloadFile, id, filename, success, error);
};*/

import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import ModalWrapper from '../../common-components/ModalWrapper';
import BoxCenter from '../../common-components/BoxCenter';

import { useStyles } from './styles';

import {
  chooseCounty,
  chooseGender,
  chooseContactPersonPosition,
  chooseMemberType,
} from '../../../helpers/database';
import CustomButton from '../../common-components/CustomButton';
import { useGlobalStyles } from '../../../customStyles';

export default function CompanyMasterPopUp({
  isOpen,
  onClose,
  contactPersonList,
  companyData,
  updateCompany,
  getCompany,
  setCompanyData,
  onPersonModalOpen,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();
  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (!isOpen) {
      reset({
        Name: '',
      });
    } else setSelectedContactPerson(companyData.Master);
  }, [isOpen]);

  const [selectedContactPerson, setSelectedContactPerson] = useState(null);

  const handleRowClick = (row) => {
    setSelectedContactPerson(row);
    onPersonModalOpen(row)
  };

  const handleSubmit = async () => {
    if (companyData.FK_ContactPerson != selectedContactPerson.ID) {
      await updateCompany(
        { ID: companyData.ID, FK_ContactPerson: selectedContactPerson.ID },
        getCompany,
      );
    }

    onClose();
  };

  // PersonPopUp

  const [isPersonPopUpOpen, setIsPersonPopUpOpen] = useState(false);

  const onPersonPopUpClose = async () => {
    setIsPersonPopUpOpen(false);
  };

  // const onPersonApply = async () => {

  // };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Ansprechperson" className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Person</TableCell>
              <TableCell width="150px">Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contactPersonList?.map((row) => (
              <TableRow
                key={row.ID}
                selected={selectedContactPerson?.ID == row.ID}
                onClick={() => handleRowClick(row)}
              >
                <TableCell>
                  {row.Person?.Firstname} {row.Person?.Lastname}
                </TableCell>
                <TableCell>
                  {chooseContactPersonPosition(row.Position)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BoxCenter>
        <CustomButton
          disabled={!selectedContactPerson?.ID}
          value="Speichern"
          onClick={() => handleSubmit()}
          sx={{ width: '180px' }}
        />
      </BoxCenter>
    </ModalWrapper>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FormSelect from '../../../../common-components/FormSelect';
import CountySelect from '../../../../common-components/CountySelect';
import FormDatePicker from '../../../../common-components/FormDatePicker';
import FormBooleanSelect from '../../../../common-components/FormBooleanSelect';

import {
  chooseGender,
  chooseContactPersonPosition,
  chooseMemberType,
  getBoolean,
  getDate,
  getPerson,
} from '../../../../../helpers/database';

import FormTextField from '../../../../common-components/FormTextField';

import { CompanyContext } from '../../CompanyContext';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../../../customStyles';

export default function EditableTables({
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [emailError, setEmailError] = useState(false);
  const [privateEmailError, setPrivateEmailError] = useState(false);
  const { companyData, setUpdatedCompanyFields } = useContext(CompanyContext);

  const createData = ((name, value) => {
    return { name, value };
  });

  const { control, reset } = useForm({
    defaultValues: {
      Name: '',
      Abbreviation: '',
      WKO: '',
      Founding: '',
      County: '',
      Commercialregister: '',
      ZVR: '',
      Employees: '',
      IsLarge: false,
      Firstname: '',
      Lastname: '',
      Birthdate: '',
      Gender: '',
      Position: '',
      Family: '',

      Fax: null,
      Telephone: null,
      TelephonePrivate: null,
      Mobile: null,
      MobilePrivate: null,
      Email: null,
      EmailEvent: null,
      EmailFinancial: null,
      EmailNewsletter: null,
      Homepage: null,

      IsLetterApproval: true,
      IsEmailApproval: true,
      IsTelephoneApproval: true,
      IsMobileApproval: true,
      IsSMSApproval: true,
      IsSocialMediaApproval: true,
      IsMessengerApproval: true,
      IsNewsletterApproval: true,

      WhatsApp: null,
      Telegram: null,
      Messenger: null,
      Signal: null,
      Xing: null,
      LinkedIn: null,
      Facebook: null,
      Instagram: null,
      Twitter: null,
      TikTok: null,
    },
  });

  const values = useWatch({ control });

  setUpdatedCompanyFields(values);

  useEffect(() => {
    if (companyData) {
      const defaultValues = {
        Name: companyData.Name,
        Abbreviation: companyData.CompanyForm?.Abbreviation,
        WKO: companyData.WKO,
        Founding: companyData.Founding,
        County: companyData.County,
        Commercialregister: companyData.Commercialregister,
        ZVR: companyData.ZVR,
        Employees: companyData.Employees,
        IsLarge: companyData.IsLarge,
        Firstname: companyData.ContactPerson?.Person?.Firstname,
        Lastname: companyData.ContactPerson?.Person?.Lastname,
        Birthdate: companyData.ContactPerson?.Person?.Birthdate,
        Gender: companyData.ContactPerson?.Person?.Gender,
        Position: companyData.ContactPerson?.Position,
        Family: companyData.ContactPerson?.Person?.Family,
        Fax: companyData.CommunicationData?.Fax,
        Telephone: companyData.CommunicationData?.Telephone,
        TelephonePrivate: companyData.CommunicationData?.TelephonePrivate,
        Mobile: companyData.CommunicationData?.Mobile,
        MobilePrivate: companyData.CommunicationData?.MobilePrivate,
        Email: companyData.CommunicationData?.Email,
        EmailEvent: companyData.CommunicationData?.EmailEvent,
        EmailFinancial: companyData.CommunicationData?.EmailFinancial,
        EmailNewsletter: companyData.CommunicationData?.EmailNewsletter,
        Homepage: companyData.CommunicationData?.Homepage,

        IsEmailApproval: companyData.CommunicationData?.IsEmailApproval ?? true,
        IsTelephoneApproval: companyData.CommunicationData?.IsTelephoneApproval ?? true,
        IsMobileApproval: companyData.CommunicationData?.IsMobileApproval ?? true,
        IsLetterApproval: companyData.CommunicationData?.IsLetterApproval ?? true,
        IsSMSApproval: companyData.CommunicationData?.IsSMSApproval ?? true,
        IsMessengerApproval: companyData.CommunicationData?.IsMessengerApproval ?? true,
        IsNewsletterApproval: companyData.CommunicationData?.IsNewsletterApproval ?? true,
        IsSocialMediaApproval: companyData.CommunicationData?.IsSocialMediaApproval ?? true,

        WhatsApp: companyData.CommunicationData?.WhatsApp,
        Telegram: companyData.CommunicationData?.Telegram,
        Messenger: companyData.CommunicationData?.Messenger,
        Signal: companyData.CommunicationData?.Signal,
        Xing: companyData.CommunicationData?.Xing,
        LinkedIn: companyData.CommunicationData?.LinkedIn,
        Facebook: companyData.CommunicationData?.Facebook,
        Instagram: companyData.CommunicationData?.Instagram,
        Twitter: companyData.CommunicationData?.Twitter,
        TikTok: companyData.CommunicationData?.TikTok,
      };
      reset(defaultValues);
    }
  }, [companyData]);

  const tableRightRows = [
    createData('Name:', getPerson(companyData.Master?.Person)),
    createData('Geburtsdatum:', getDate(companyData.Master?.Person?.Birthdate)),
    createData('Geschlecht:', chooseGender(companyData.Master?.Person?.Gender)),
    createData('Position:', chooseContactPersonPosition(companyData.Master?.Position)),
    createData('SWV Mitglied:', chooseMemberType(companyData.Master?.Person?.Member?.Type)),
    createData('SWV Familie:', getBoolean(companyData.Master?.Person?.Family)),
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl>
        <Grid container mt={6} columnSpacing={6}>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormTextField
                name="Name"
                label="Name:"
                control={control}
                required
              />
              <FormSelect
                name="Abbreviation"
                label="Form"
                control={control}
                value={values.Abbreviation}
                required
              >
                <MenuItem value="np EU">nicht protokollierter Einzelunternehmer</MenuItem>
                <MenuItem value="EU">Einzelunternehmer</MenuItem>
                <MenuItem value="OHG">Offene Handelsgesellschaft</MenuItem>
                <MenuItem value="KG">Kommanditgesellschaft</MenuItem>
                <MenuItem value="GesmbH">Gesellschaft mit beschränkter Haftung</MenuItem>
                <MenuItem value="AG">Aktiengesellschaft</MenuItem>
                <MenuItem value="GK">Gebietskörperschaft</MenuItem>
                <MenuItem value="e.V.">Verein</MenuItem>
                <MenuItem value="EWG">Erwerbs- u. Wirtschaftsgenossenschaften</MenuItem>
                <MenuItem value="Sonst.">Sonstige Rechtsform</MenuItem>
                <MenuItem value="JP">(Nicht) prot. Unternehmen jurist. Person</MenuItem>
                <MenuItem value="OEG">Offene Erwerbsgesellschaften</MenuItem>
                <MenuItem value="KEG">Kommandit-Erwerbsgesellschaften</MenuItem>
                <MenuItem value="Ausl.">Ausländische Rechtsform</MenuItem>
                <MenuItem value="Anst.">Anstalt</MenuItem>
                <MenuItem value="IV">Europäische wirtschaftliche Interessenvereinigung</MenuItem>
                <MenuItem value="Fonds">Fonds</MenuItem>
                <MenuItem value="PP">Politische Partei</MenuItem>
                <MenuItem value="Priv. Stift.">Privatstiftung</MenuItem>
                <MenuItem value="Rel.">Kirchen und gesetzlich anerkannte Religionsgesellschaften</MenuItem>
                <MenuItem value="Spk">Sparkasse</MenuItem>
                <MenuItem value="Stift.">Stiftung</MenuItem>
                <MenuItem value="SV">Sozialversicherungsträger</MenuItem>
                <MenuItem value="VVG">Versicherungsverein auf Gegenseitigkeit</MenuItem>
                <MenuItem value="Verl.">Verlassenschaft</MenuItem>
                <MenuItem value="EUG">Europäische Gesellschaft</MenuItem>
                <MenuItem value="EUGen.">Europäische Genossenschaft</MenuItem>
                <MenuItem value="OG">Offene Gesellschaft</MenuItem>
                <MenuItem value="Unbek">Unbekannt</MenuItem>
              </FormSelect>
              <FormTextField
                name="WKO"
                label="WK-Nummer:"
                control={control}
                type="number"
                required
              />
              <FormDatePicker
                name="Founding"
                label="Gründung:"
                control={control}
              />
              <CountySelect
                name="County"
                label="Kammer:"
                control={control}
                required
                value={values?.County}
              />
              <FormTextField
                name="Commercialregister"
                label="Firmenbuch:"
                control={control}
              />
              <FormTextField
                name="ZVR"
                label="ZVR:"
                control={control}
              />
              <FormSelect
                name="Employees"
                label="MitarbeiterInnen:"
                control={control}
                value={values.Employees}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1 - 25</MenuItem>
                <MenuItem value="26">26 - 500</MenuItem>
                <MenuItem value="500">500+</MenuItem>
              </FormSelect>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box>
              <TableContainer>
                <Table>
                  <TableBody>
                    {tableRightRows?.map((row) => (
                      <TableRow
                        key={row.name}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell align="right">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} mt={5}>
          <Box className={cssClass.flexColumnCenter}>
            <Typography variant="h2" mb={1}>
              Kontaktdaten / Erlaubnis
            </Typography>
          </Box>
          <Grid container mt={1} columnSpacing={6}>
            <Grid xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormTextField
                  name="Telephone"
                  label="Telefon"
                  control={control}
                />
                <FormTextField
                  name="TelephonePrivate"
                  label="Telefon (privat)"
                  control={control}
                />
                <FormTextField
                  name="Mobile"
                  label="Mobil"
                  control={control}
                />
                <FormTextField
                  name="MobilePrivate"
                  label="Mobil (privat)"
                  control={control}
                />
                <FormTextField
                  name="Email"
                  label="E-Mail"
                  control={control}
                  type="email"
                />
                <FormTextField
                  name="EmailEvent"
                  label="E-Mail (Veranstaltungen)"
                  control={control}
                  type="email"
                />
                <FormTextField
                  name="EmailFinancial"
                  label="E-Mail (Finanzen)"
                  control={control}
                  type="email"
                />
                <FormTextField
                  name="EmailNewsletter"
                  label="E-Mail (Newsletter)"
                  control={control}
                  type="email"
                />
                <FormTextField
                  name="Homepage"
                  label="Homepage"
                  control={control}
                />
                <FormTextField
                  name="Fax"
                  label="Fax"
                  control={control}
                />
                <FormTextField
                  name="WhatsApp"
                  label="WhatsApp"
                  control={control}
                />
                <FormTextField
                  name="Telegram"
                  label="Telegram"
                  control={control}
                />
                <FormTextField
                  name="Messenger"
                  label="Messenger"
                  control={control}
                />
                <FormTextField
                  name="Signal"
                  label="Signal"
                  control={control}
                />
                <FormTextField
                  name="Xing"
                  label="Xing"
                  control={control}
                />
                <FormTextField
                  name="LinkedIn"
                  label="LinkedIn"
                  control={control}
                />
                <FormTextField
                  name="Facebook"
                  label="Facebook"
                  control={control}
                />
                <FormTextField
                  name="Instagram"
                  label="Instagram"
                  control={control}
                />
                <FormTextField
                  name="Twitter"
                  label="Twitter"
                  control={control}
                />
                <FormTextField
                  name="TikTok"
                  label="TikTok"
                  control={control}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormBooleanSelect
                  name="IsLetterApproval"
                  label="Brief"
                  control={control}
                  value={values.IsLetterApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsEmailApproval"
                  label="Email"
                  control={control}
                  value={values.IsEmailApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsTelephoneApproval"
                  label="Telefon"
                  control={control}
                  value={values.IsTelephoneApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsMobileApproval"
                  label="Mobil"
                  control={control}
                  value={values.IsMobileApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsSMSApproval"
                  label="SMS"
                  control={control}
                  value={values.IsSMSApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsMessengerApproval"
                  label="Messenger"
                  control={control}
                  value={values.IsMessengerApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsNewsletterApproval"
                  label="Newsletter"
                  control={control}
                  value={values.IsNewsletterApproval}
                  required
                />
                <FormBooleanSelect
                  name="IsSocialMediaApproval"
                  label="Soziale Medien"
                  control={control}
                  value={values.IsSocialMediaApproval}
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    </LocalizationProvider>
  );
}
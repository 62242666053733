import React from 'react';
import TableCell from '@mui/material/TableCell';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useStyles } from './styles';

export default function DownloadCell({ func, id, name }) {
  const classes = useStyles();

  return (
    <TableCell className={classes.link} align="center">
      <FileDownloadIcon
        color="primary"
        sx={{ fontSize: 20, cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          func(id, name);
        }}
      />
    </TableCell>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormControl, Box, Button, MenuItem } from '@mui/material';

import * as BusinessGroupService from '../../../services/requests/businessGroup';
import BusinessSubcategoryPopUp from '../BusinessSubcategory';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import CountySelect from '../../common-components/CountySelect';
import FormSelect from '../../common-components/FormSelect';
import CustomSelect from '../../common-components/CustomSelect';

import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';

export default function BusinessGroupPopUp({
  isOpen,
  onClose,
  businessCategoryList,
  masterBusinessGroupId,
  handleApply,
}) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  const [masterBusinessGroup, setMasterBusinessGroup] = useState(null);
  const [chosenBusinessGroup, setChosenBusinessGroup] = useState(null);
  const [businessGroupList, setBusinessGroupList] = useState(null);

  const [isBusinessSubcategoryModalOpen, setIsBusinessSubcategoryModalOpen] = useState(false);

  const { control, reset, setValue } = useForm({
    defaultValues: {
      businessCategory: '',
      county: '',
      businessSubcategory: '',
      businessSubcategoryNumber: '',
      businessSubcategoryName: '',
      number: '',
      name: '',
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      if (masterBusinessGroupId) {
        BusinessGroupService.getOne(masterBusinessGroupId, setMasterBusinessGroup);
      }
    } else {
      setMasterBusinessGroup(null);
      setChosenBusinessGroup(null);
      setBusinessGroupList(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (masterBusinessGroup) {
      const defaultValues = {
        businessCategory: masterBusinessGroup.BusinessSubcategory.BusinessCategory.ID,
        county: masterBusinessGroup.BusinessSubcategory.County,
        businessSubcategory: masterBusinessGroup.BusinessSubcategory.ID,
        businessSubcategoryNumber: masterBusinessGroup.BusinessSubcategory.Number,
        businessSubcategoryName: masterBusinessGroup.BusinessSubcategory.Name,
        number: masterBusinessGroup.Number,
        name: masterBusinessGroup.Name,
      };
      reset(defaultValues);
    }
  }, [masterBusinessGroup]);

  const onBusinessCategoryClicked = () => {
    setValue('businessSubcategory', '');
    setValue('businessSubcategoryNumber', '');
    setValue('businessSubcategoryName', '');
    setChosenBusinessGroup(null);
    setBusinessGroupList(null);
  };

  const onCountyClicked = () => {
    setValue('businessSubcategory', '');
    setValue('businessSubcategoryNumber', '');
    setValue('businessSubcategoryName', '');
    setChosenBusinessGroup(null);
    setBusinessGroupList(null);
  };

  const onSearchClicked = useCallback(async() => {
    setChosenBusinessGroup(null);

    await BusinessGroupService.getList({
      subcategoryId: values?.businessSubcategory,
      number: values.number,
      name: values.name,
    }, setBusinessGroupList);
  }, [values])

  const onApplyClicked = async () => {
    handleApply({ ID: chosenBusinessGroup.ID, Name: chosenBusinessGroup.Name });
  };

  // BusinessSubcategoryPopUp

  const onBusinessSubcategoryModalOpen = () => {
    setIsBusinessSubcategoryModalOpen(true);
  };

  const onBusinessSubcategoryModalClose = () => {
    setIsBusinessSubcategoryModalOpen(false);
  };

  const onBusinessSubcategoryApply = (businessSubcategory) => {
    setValue('businessSubcategory', businessSubcategory.ID);
    setValue('businessSubcategoryNumber', businessSubcategory.Number);
    setValue('businessSubcategoryName', businessSubcategory.Name);
    setIsBusinessSubcategoryModalOpen(false);
    setChosenBusinessGroup(null);
  };

  const disabled =
    !values?.businessSubcategory ||
    (!values?.number && !values?.name);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Berufsgruppe">
      <FormControl>
        <FormSelect
          label="Sparte"
          name="businessCategory"
          control={control}
          required
          onClick={onBusinessCategoryClicked}
          value={values?.businessCategory || ''}
        >
          {businessCategoryList?.map((row) => {
            return (
              <MenuItem
                key={row.ID}
                value={row.ID}
              >
                {row.Name}
              </MenuItem>
            );
          })}
        </FormSelect>
        <CountySelect
          required
          name="county"
          control={control}
          onClick={onCountyClicked}
          value={values?.county}
        />
        <CustomSelect
          required
          placeholder="Fachgruppe"
          disabled={!values?.businessCategory || !values?.county}
          value={values?.businessSubcategory}
          valueToDisplay={values?.businessSubcategory ? values?.businessSubcategoryNumber + ', ' + values?.businessSubcategoryName : ''}
          onClick={onBusinessSubcategoryModalOpen}
        />
        <FormTextField
          name="number"
          label="Nummer"
          control={control}
          type="number"
        />
        <FormTextField
          name="name"
          label="Name"
          control={control}
        />

        <Box className={cssClass.listContainer}>
          {businessGroupList?.map((row) => <p
            key={row.ID}
            className={chosenBusinessGroup?.ID == row.ID ? cssClass.chosen : cssClass.notchosen}
            onClick={() => setChosenBusinessGroup(row)}
          >
            {row.Number}, {row.Name}
          </p>)}
        </Box>

        <BoxCenter>
          <Button
            onClick={() => onApplyClicked()}
            disabled={!chosenBusinessGroup}
          >
            Übernehmen
          </Button>
          <Button
              onClick={() => onSearchClicked()}
              disabled={disabled}
            >
            Suchen
          </Button>
        </BoxCenter>

        <BusinessSubcategoryPopUp
          isOpen={isBusinessSubcategoryModalOpen}
          onClose={onBusinessSubcategoryModalClose}
          currentBusinessCategory={values.businessCategory}
          currentCounty={values.county}
          currentBusinessSubcategoryNumber={values.businessSubcategoryNumber}
          currentBusinessSubcategoryName={values.businessSubcategoryName}
          handleApply={onBusinessSubcategoryApply}
        />
      </FormControl>
    </ModalWrapper>
  );
}
